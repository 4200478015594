
/*
 * Backend Layout
 */

/* Wrapper */

.be-wrapper:not(.be-grid-preserve)
{
	margin-left: 0;
}

.be-wrapper:not(.be-grid-preserve) > *
{
	padding-left: 0;
}

.be-wrapper
{
	max-width: 100vw;
	min-height: 100vh;
}

.be-wrapper > div
{
	min-height: 100vh;
	padding-left: 0;
}

/* Header */
.be-header{
	width: 100%;
	height: 100px;
	background: url(/image/header-bg.png) repeat-x center -24px #ffffff;
	border-bottom: 2px #e6e6e6 solid;

}

.be-header h1
{
	margin-bottom: 0;
}

.be-header h2
{
	color: #569aca;
	font-weight: normal;
}

/* Toolbar */
.header_logo{
	display: block;
	z-index: 100;
	width: 198px;
	height: 53px;
	padding: 0;
	margin: 29px 0 0 30px;
	overflow: hidden;
	text-indent: -300px;
	font-size: 15px;
	background: url(/image/logo_synapse.png) no-repeat 0 0;
}

.header_logo:hover {
	cursor: pointer;
}

.be-toolbar .uk-subnav > * {
	margin-top: 15px;
	line-height: 35px;
}

.be-toolbar .uk-subnav-line > *:nth-child(n+2)::before {
	border-left: none;
}

.be-toolbar .be-userinfo {
	font-weight: bold;
}

/* Sidebar */

.be-sidebar-wrapper::after
{
	background: #ffffff;
	bottom: 0;
	content: "";
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	width: 1px;
}

.be-sidebar-wrapper
{
	background: #ffffff;
	width: 100px;
	position: relative;
	text-align: center;
}

.be-sidebar-wrapper .be-sidebar-menu-container
{
	padding: 30px 0;
	text-align: center;
	z-index: 1002;
}

.be-sidebar-wrapper .be-sidebar-menu-container .be-sidebar-logo
{
	display: inline-block;
	margin: 0 auto 60px;
}

.be-sidebar-wrapper .be-sidebar-menu-container .be-sidebar-nav
{
	margin-bottom: 40px;
}

/* Content */
.be-content
{
  margin-left: 15px;
}

.be-content-wrapper
{
	width: calc(100vw - 100px);
	position: relative;
}

.be-content-wrapper .be-content-container
{
	box-sizing: border-box;
	padding: 15px 30px;
}

.be-login{
	padding: 15px;
	background: #fff;
	border: 1px #d2d2d2 solid;
	display: table;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	box-shadow: 4px 4px 4px #e8e8e8;
	-moz-box-shadow: 4px 4px 4px #e8e8e8;
	-webkit-box-shadow: 4px 4px 4px #e8e8e8;
}

/* Main */

.be-main
{
	min-height: calc(100vh - 175px);
	margin: 1% auto;
	width: 98%;
	display: table;
}

/* - Subnav */

.be-subnav .uk-subnav-line > *:nth-child(n+2)::before
{
	border-left: none;
}

.be-subnav a > i
{
	padding-right: 5px;
}

/* - Search and filter */

.be-search-n-filter a > i
{
	padding-right: 5px;
}

/* - Form */

label
{
	font-weight: 600;
}

.padding-top-small{
	padding-top: 5px;
}

.be-required label
{
	color: #569aca;
	font-weight: bold;
}

.be-note
{
	font-size: 14px;
	font-style: italic;
}

/* Bottom */

.be-bottom
{
	margin-top: 30px;
}

.be-copyright
{
	font-size: 12px;
}


/* Main subnav menu */

.be-sidebar-subnav
{
	position: absolute;
	background: #569aca;
	display: block;
	top: 0;
	left: 100px;
	width: 305px;
	padding: 40px 30px;
	height: 100vh;
	text-align: left;
	z-index: 2;
}

.be-sidebar-subnav h2
{
	color: #ffffff;
	font-size: 30px;
	padding-bottom: 50px;
	margin-bottom: 15px;
	border-bottom: 1px solid #0a2d45;
}

/* - navbar items */

.be-sidebar-subnav .uk-nav-navbar > li > a
{
	color: #ffffff;
}

.be-sidebar-subnav .uk-nav-navbar > li >  a > i
{
	display: block;
	float: left;
	width: 25px;
	line-height: 20px;
}

.be-sidebar-subnav .uk-nav-navbar > li >  a > span
{
	display: block;
	margin-left: 25px;
	line-height: 20px;
}

/* - Accordion */

.be-sidebar-subnav h3.uk-accordion-title
{
	background: none;
	margin-bottom: 15px;
	margin-top: 15px;
	padding: 0;
	color: #0a2d45;
	font-size: 18px;
	line-height: 30px;
	border: none;
	border-radius: 0;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title:first-child
{
	margin-top: 0;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title::after
{
	content: '\f067';
	background: #0a2d45;
	display: block;
	float: right;
	width: 30px;
	height: 30px;
	font-family: 'FontAwesome';
	font-size: 14px;
	color: #ffffff;
	line-height: 30px;
	text-align: center;
	border-radius: 3px;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title.uk-active::after
{
	content: '\f068';
}

.be-sidebar-subnav .uk-accordion-content
{
	padding: 0;
}


/*
 * UIKit overrides
 */

/* Tabs */

.uk-tab > li > a {
	background: #494b4d;
	border-radius: 5px;
}

.uk-tab > li.uk-disabled > a {
	background: #d2d4d3;
}

.uk-tab > li.uk-disabled > a:hover,
.uk-tab > li.uk-disabled > a:focus {
	background: #d2d4d3;
}

/* Main navbar */

.uk-navbar-nav:first-child > li:first-child > a {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.uk-navbar-nav > li > a {
	border-width: 0 0 1px 0;
	border-color: #e5e5e5;
	color: #a0a2ab;
	font-family: "Cabin",Helvetica,Arial,sans-serif;
	font-size: 36px;
	font-weight: normal;
	width: 100px;
	height: 95px;
	line-height: 95px;
	margin-left: 0;
	margin-top: 0;
	padding: 0;
}

.uk-navbar-nav > li:first-child > a {
	border-width: 1px 0 1px 0;
}

.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a.focus,
.uk-navbar-nav > li.uk-open > a,
.uk-navbar-nav > li.uk-active > a {
	background: #569aca;
	color: #ffffff;
}

/* Tables */

.uk-table
{
	margin-top: 20px;
	margin-left: 15px;
	margin-right: 15px;
}

.uk-table th {
	background: #569aca;
	color: #ffffff !important;
	border-left: 1px solid #ebedec;
}

.uk-table th:first-child
{
	border-radius: 3px 0 0 0;
	border-left: none;
}

.uk-table th:last-child
{
	border-radius: 0 3px;
}

.uk-table span.data{
	float: left;
	margin: 5px;
	width: 50px;
	height: 25px;
	overflow: hidden;
	text-align: center;
	font-family: 'Arial', sans-serif;
	font-size: 13px;
	line-height: 12px;
	padding: 4px 0 4px 0;
	border: 1px #cecece solid;
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	background: #f9f9f9;
	color: white;
}

.uk-table span.dataiah {
	float: left;
	margin: 5px;
	width: 83px;
	height: 25px;
	overflow: hidden;
	text-align: center;
	font-family: 'Arial', sans-serif;
	font-size: 11px;
	line-height: 12px;
	padding: 4px 0 4px 0;
	border: 1px #cecece solid;
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	background: #f9f9f9;
	color: white;
}

.be-content .be-list {
	width: 98%;
	margin-right: 15px;
}


.be-content .be-detail,
.be-content .be-telesuivi {
	width: 98%;
	padding: 15px;
}

.be-content .be-detail .uk-grid > * > :last-child {
	margin: 0px;
}



/* Forms */

.uk-form-select
{
	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	color: #494b4d;
	max-width: 100%;
	line-height: 30px;
}

.uk-form-select select
{
	background: none;
	border: none;
	border-radius: 0;
}

.uk-form-select span
{
	padding: 0 5px;
}

.uk-form-horizontal .uk-width-1-3 .uk-form-label {
	width: 100px !important;
}

.uk-form-horizontal .uk-width-1-3 .uk-form-controls {
	margin-left: 115px !important;
}

#frmSearch, #frmUserSearch{
	margin: 15px;
	border: 1px #dbdbdb solid;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	padding: 7px 0;
	background: #F3F3F3;
}

#frmSearch .be-inline,
#frmUserSearch .be-inline{
	display: inline-block;
	height: 10px;
	margin-left: 20px;
}

#frmSearch .be-inline input,
#frmSearch .be-inline select,
#frmUserSearch .be-inline input,
#frmUserSearch .be-inline select {
	margin-top: -5px;
}


.uk-panel-box {
	padding: 15px;
	background: #F2F2F2;
	color: #494b4d;
	border: 1px solid none;
	border-radius: 3px;
}

/* Chart */
.div_dashboard{
	width: 70%;
	-webkit-appearance: none;
	margin-top: 3px;
	padding-left: 0px;
	box-sizing: border-box;
	vertical-align: middle;
	text-align: center;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	text-shadow: 0 1px 0 none;
	min-height: 100px;
}

/* top contact */
.tool-appareillage a,
.tool-contact a {
	margin-top: -5px;
}

.uk-datepicker {
	z-index: 1050;
	width: auto;
	-webkit-animation: uk-fade 0.2s ease-in-out;
	animation: uk-fade 0.2s ease-in-out;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}
.uk-datepicker-nav {
	margin-bottom: 15px;
	text-align: center;
	line-height: 20px;
}
.uk-datepicker-nav:before,
.uk-datepicker-nav:after {
	content: "";
	display: table;
}
.uk-datepicker-nav:after {
	clear: both;
}
.uk-datepicker-nav a {
	color: #494b4d;
	text-decoration: none;
}
.uk-datepicker-nav a:hover {
	color: #494b4d;
}
.uk-datepicker-previous {
	float: left;
}
.uk-datepicker-next {
	float: right;
}
.uk-datepicker-previous:after,
.uk-datepicker-next:after {
	width: 20px;
	font-family: FontAwesome;
}
.uk-datepicker-previous:after {
	content: "\f053";
}
.uk-datepicker-next:after {
	content: "\f054";
}
.uk-datepicker-table {
	width: 100%;
}
.uk-datepicker-table th,
.uk-datepicker-table td {
	padding: 2px;
}
.uk-datepicker-table th {
	font-size: 12px;
}
.uk-datepicker-table a {
	display: block;
	width: 26px;
	line-height: 24px;
	text-align: center;
	color: #494b4d;
	text-decoration: none;
	border: 1px solid transparent;
	border-radius: 3px;
}
a.uk-datepicker-table-muted {
	color: #9fa1a0;
}
.uk-datepicker-table a:hover,
.uk-datepicker-table a:focus {
	background-color: #ffffff;
	color: #494b4d;
	outline: none;
	border-color: rgba(0, 0, 0, 0.16);
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0);
}
.uk-datepicker-table a:active {
	background-color: @global-primary-background;
	color: #494b4d;
}
.uk-datepicker-table a.uk-active {
	background: @global-primary-background;
	color: #ffffff;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0);
}

.button-appareillage {
	line-height: 45px;
	top: -8px;
	position: relative;
	font-size: 17px;
}

#frmcontactNew{
	.dap-form-initial{
		width: 43%;
	}

	.dap-complement{
		width: 53%;
		display: none;
	}

	.uk-form-row{
		margin-left: 0px;
	}

	.uk-form-label{
		width: 275px;
	}
}
#frmcontactEditDap{
	.uk-form-label {
		width: 245px;
	}
}

.inputfile{
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	border: 1px solid #e5e5e5;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer; /* "hand" cursor */
}

.inputfile + label span,
.inputfile + label label {
	padding: 0.625rem 1.25rem;
	/* 10px 20px */
}

.inputfile + label span {
	width: 51%;
	min-height: 2em;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: top;
}

.inputfile + label label {
	height: 100%;
	display: inline-block;
}


/* radio and checkbox */
.radio,
.checkbox {
	/* 1 */
	display: inline-block;
	height: 16px;
	width: 16px;
	/* 2 */
	overflow: hidden;
	/* 3 */
	margin-top: -4px;
	vertical-align: middle;
	/* 4 */
	-webkit-appearance: none;
	-moz-appearance: none;
	/* 5 */
	background-color: transparent;
	/* 6 */
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border: 1px solid #cccccc;
	transition: 0.2s ease-in-out;
	transition-property: background-color, border;
}
.radio {
	border-radius: 50%;
}
/* Focus */
.radio:focus,
.checkbox:focus {
	outline: none;
	border-color: #1e87f0;
}
/*
 * Checked
 */
.radio:checked,
.checkbox:checked {
	background-color: #1e87f0;
	border-color: transparent;
}
/* Focus */
.radio:checked:focus,
.checkbox:checked:focus {
	background-color: #0e6dcd;
}
/*
 * Icons
 */
.radio:checked {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.checkbox:checked {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
/*
 * Disabled
 */
.radio:disabled,
.checkbox:disabled {
	background-color: #f8f8f8;
	border-color: #e5e5e5;
}
.radio:disabled:checked {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.checkbox:disabled:checked {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:indeterminate {
	background-image: none !important;
	background-color: transparent !important;;
	border: 1px solid #cccccc !important;;
}

.bg-color-red{
	background-color: red !important;
}
.bg-color-orange{
	background-color: orange !important;
}
.bg-color-green{
	background-color: green !important;
}

.button_com{
	border: none !important;
}

/* Loader */

.loader {
	margin: 100px auto;
	font-size: 25px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: load5 1.1s infinite ease;
	animation: load5 1.1s infinite ease;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}


@-webkit-keyframes load5 {
	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.5), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.5), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.5), 2.5em 0em 0 0em rgba(30, 135, 240, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.5), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.5), 0em 2.5em 0 0em rgba(30, 135, 240, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.5), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.5), -2.6em 0em 0 0em rgba(30, 135, 240, 0.7), -1.8em -1.8em 0 0em #ffffff;
	}
}

@keyframes load5 {
	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.5), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.5), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.5), 2.5em 0em 0 0em rgba(30, 135, 240, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.5), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.2), -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.5), 0em 2.5em 0 0em rgba(30, 135, 240, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(30, 135, 240, 0.2), -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.5), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(30, 135, 240, 0.2), 1.8em -1.8em 0 0em rgba(30, 135, 240, 0.2), 2.5em 0em 0 0em rgba(30, 135, 240, 0.2), 1.75em 1.75em 0 0em rgba(30, 135, 240, 0.2), 0em 2.5em 0 0em rgba(30, 135, 240, 0.2), -1.8em 1.8em 0 0em rgba(30, 135, 240, 0.5), -2.6em 0em 0 0em rgba(30, 135, 240, 0.7), -1.8em -1.8em 0 0em #ffffff;
	}
}

.datepicker-custom{
	border: none !important;
	color: #666;
}

